import { useEffect, useState } from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { AuthApi } from "../../apis/AuthApi";
import { Member, MemberApi } from "../../apis/MemberApi";
import { ProductDiscountCreditApi, ProductDiscountCreditItem } from "../../apis/ProductDiscountCreditApi";
import { BootstrapButton } from "../../components/BootstrapButton/BootstrapButton";
import { BootstrapInput } from "../../components/BootstrapInput/BootstrapInput";
import { Loader } from "../../components/Loader/Loader";
import { useAuthenticatedUser } from "../../context/AuthContext";
import "./account-page.scss";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { Details } from "./Details/Details";
import { Ledger } from "./Ledger/Ledger";
import { AffiliateProgram } from "./AffiliateProgram/AffiliateProgram";
import { Membership } from "./Membership/Membership";
import { StripeApi, Subscription } from "../../apis/StripeApi";
import { FamilyTree } from "./FamilyTree/FamilyTree";
import { CustomerApi } from "../../apis/CustomerApi";
import { PaymentMethod } from "@stripe/stripe-js";
import { OrderHistory } from "./OrderHistory/OrderHistory";
import { Matrix } from "./Matrix/Matrix";
import { QualificationApi } from "../../apis/QualificationApi";

interface AccountPageProps {}

interface Stage {
    text: string;
    to: StageTo;
}

let navItems: Stage[] = [
    { text: "Account Details", to: "Account Details" },
    { text: "Product Discount Ledger", to: "Ledger" },
    { text: "Membership / Billing", to: "Membership" },
    { text: "Affiliate Program", to: "Affiliate" },
    { text: "Order History", to: "Order History" },
    { text: "Family Tree", to: "Family Tree" },
    { text: "Matrix", to: "Matrix" },
];

export type StageTo = "Account Details" | "Ledger" | "Affiliate" | "Membership" | "Family Tree" | "Order History" | "Matrix";

export const AccountPage = ({}: AccountPageProps) => {
    const [loadingAccountPage, setLoadingAccountPage] = useState(true);
    const { authMember, updateAuthMember, logout } = useAuthenticatedUser();
    const [accountDetailsForm, setAccountDetailsForm] = useState<Member>();
    const [patchingProps, setPatchingProps] = useState<{ prop: string; value: string }[]>([]);
    const [pdcBalance, setPdcBalance] = useState(0);
    const [pdcLedger, setPdcLedger] = useState<ProductDiscountCreditItem[]>([]);
    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
    const [genealogyTree, setGenealogyTree] = useState<any[]>([]);
    const [matrixTree, setMatrixTree] = useState<any[]>([]);
    const [membership, setMembership] = useState<any>();
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
    const [orderHistory, setOrderHistory] = useState<any[]>([]);
    const [orderHistoryCount, setOrderHistoryCount] = useState(0);
    const [stage, setStage] = useLocalStorage<StageTo>(
        "account-state",
        window.location.search.includes("updateCC=true") ? "Membership" : "Account Details"
    );
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        if (authMember && !accountDetailsForm) {
            getMember();
            getMembershipDetails();
            getFamilyTree();
            getMatrix();
        }
    }, [authMember]);

    useEffect(() => {
        if (authMember) {
            getProductDiscountCredits();
        }
    }, [authMember, loadingAccountPage]);

    useEffect(() => {
        getOrderHistory();
    }, [offset, authMember]);

    const getOrderHistory = () => {
        if (authMember) {
            MemberApi.OrderHistory(authMember.email, 15, offset)
                .then((response) => {
                    if (response.success) {
                        setOrderHistory(response.results);
                        setOrderHistoryCount(response.resultCount);
                    }
                })
                .catch(console.error)
                .finally(() => {
                    if (stage === "Order History") {
                        setTimeout(() => {
                            setLoadingAccountPage(false);
                        }, 500);
                    }
                });
        }
    };

    const getMember = () => {
        if (authMember) {
            MemberApi.GetOneMember(authMember.member_id)
                .then(setAccountDetailsForm)
                .catch(console.error)
                .finally(() => {
                    if (stage === "Account Details" || stage === "Affiliate") {
                        setTimeout(() => {
                            setLoadingAccountPage(false);
                        }, 500);
                    }
                });
        }
    };

    const getProductDiscountCredits = async () => {
        if (authMember) {
            ProductDiscountCreditApi.GetMemberBalance(authMember.member_id)
                .then((response: any) => {
                    if (response.success) {
                        setPdcBalance(response.results);
                    }
                })
                .catch(console.error);
            ProductDiscountCreditApi.GetMemberLedger(authMember.member_id)
                .then((response: any) => {
                    if (response.success) {
                        setPdcLedger(response.results);
                    }
                })
                .catch(console.error)
                .finally(() => {
                    if (stage === "Ledger") {
                        setTimeout(() => {
                            setLoadingAccountPage(false);
                        }, 500);
                    }
                });
        }
    };

    const getFamilyTree = async () => {
        if (authMember) {
            MemberApi.GetGenealogyTree(authMember.member_id)
                .then((response: any) => {
                    setGenealogyTree(response.results);
                })
                .catch(console.error)
                .finally(() => {
                    if (stage === "Family Tree") {
                        setTimeout(() => {
                            setLoadingAccountPage(false);
                        }, 500);
                    }
                });
        }
    };

    const getMatrix = async () => {
        if (authMember) {
            const _runDate = new Date();
            _runDate.setMonth(_runDate.getMonth() - 1);
            const month = _runDate.toLocaleDateString("default", { month: "short" });
            const year = _runDate.getFullYear().toString();
            QualificationApi.GetMatrixMemberTable(month, year, authMember.member_id)
                .then((response: any) => {
                    console.log(response);
                    if (response.success) {
                        setMatrixTree(response.results);
                    } else {
                        setMatrixTree([]);
                    }
                })
                .catch(console.error)
                .finally(() => {
                    if (stage === "Matrix") {
                        setTimeout(() => {
                            setLoadingAccountPage(false);
                        }, 500);
                    }
                });
        }
    };

    const handleSetDefaultPaymentMethod = async (customer_id: string, card_id: string) => {
        await CustomerApi.SetDefaultPaymentMethod(customer_id, card_id).catch(console.error);
    };

    const handlePaymentMethods = (paymentMethods: PaymentMethod[], defaultCard: string, customer_id: string) => {
        const listOfPaymentMethods: PaymentMethod[] = [];
        let paymentMethodToRemove: PaymentMethod | undefined = undefined;
        for (let i = 0; i < paymentMethods.reverse().length; i++) {
            let paymentMethod = paymentMethods[i];
            let paymentMethodAlreadyExistsIndex = listOfPaymentMethods.findIndex(
                (payment) =>
                    payment.id === defaultCard &&
                    payment.card?.last4 === paymentMethod.card?.last4 &&
                    payment.card?.exp_month === paymentMethod.card?.exp_month &&
                    payment.card?.exp_year === paymentMethod.card?.exp_year
            );
            if (paymentMethodAlreadyExistsIndex === -1) {
                listOfPaymentMethods.push(paymentMethod);
            } else {
                if (paymentMethodToRemove === undefined) paymentMethodToRemove = paymentMethod;
            }
        }
        console.log(paymentMethodToRemove);
        if (paymentMethodToRemove) {
            CustomerApi.RemovePaymentMethod(customer_id, paymentMethodToRemove.id);
        }

        return listOfPaymentMethods.reverse();
    };

    const getMembershipDetails = async () => {
        if (authMember) {
            await CustomerApi.GetCustomer(authMember.customer_id).then((customerResponse: any) => {
                if (customerResponse) {
                    let defaultCard =
                        customerResponse.customer.default_payment_method !== null
                            ? customerResponse.customer.default_payment_method
                            : customerResponse.paymentMethods.length > 0
                            ? customerResponse.paymentMethods[0].id
                            : null;
                    if (
                        customerResponse.customer.default_payment_method === null &&
                        customerResponse.paymentMethods &&
                        customerResponse.paymentMethods.length > 0
                    ) {
                        handleSetDefaultPaymentMethod(authMember.customer_id, customerResponse.paymentMethods[0].id);
                    }

                    StripeApi.GetActiveSubscriptions()
                        .then((response: any) => {
                            let cleanPaymentMethods = handlePaymentMethods(customerResponse.paymentMethods, defaultCard, authMember.customer_id);
                            setSubscriptions(response.results);
                            setMembership(customerResponse.membership);
                            setPaymentMethods(cleanPaymentMethods);
                            updateAuthMember({
                                ...authMember,
                                active: customerResponse.valid_member,
                                default_payment_method: defaultCard,
                            });
                        })
                        .finally(() => {
                            if (stage === "Membership") {
                                setTimeout(() => {
                                    setLoadingAccountPage(false);
                                }, 500);
                            }
                        });
                }
            });
        }
    };

    const updatePatchingProps = (property: string, value: any, remove: boolean = false) => {
        if (remove) {
            setPatchingProps(patchingProps.filter((object) => object.prop !== property));
        } else {
            const index = patchingProps.findIndex((obj) => obj.prop === property);
            if (index !== -1) {
                let copyOfPatchingProps = [...patchingProps];
                copyOfPatchingProps[index].value = value;
                setPatchingProps(copyOfPatchingProps);
            } else {
                setPatchingProps([...patchingProps, { prop: property, value }]);
            }
        }
    };

    return (
        <>
            {loadingAccountPage ? (
                <Loader />
            ) : (
                <>
                    {accountDetailsForm && (
                        <Container fluid className="py-0 py-md-4">
                            <Tab.Container id="account-page" defaultActiveKey={stage}>
                                <Row className="position-relative d-flex justify-content-center">
                                    <Col className="account-page__nav-item" sm={12} md={12} lg={4} xl={2}>
                                        <Nav className="flex-column">
                                            {navItems.map((navItem) => (
                                                <Nav.Item key={navItem.text}>
                                                    <Nav.Link onClick={() => setStage(navItem.to)} eventKey={navItem.to}>
                                                        {navItem.text}
                                                    </Nav.Link>
                                                </Nav.Item>
                                            ))}
                                            <Nav.Item className="border-top account-page__nav-item-disabled">
                                                <Nav.Link>
                                                    Membership:{" "}
                                                    {authMember?.active ? (
                                                        <span className="tsn-green">ACTIVE</span>
                                                    ) : (
                                                        <span className="tsn-red">INACTIVE</span>
                                                    )}
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col className="border-left" sm={12} md={12} lg={8} xl={8}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="Account Details">
                                                <Details
                                                    accountDetailsForm={accountDetailsForm}
                                                    setAccountDetailsForm={setAccountDetailsForm}
                                                    updatePatchingProps={updatePatchingProps}
                                                    patchingProps={patchingProps}
                                                    setPatchingProps={setPatchingProps}
                                                />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Ledger">
                                                <Ledger pdcBalance={pdcBalance} pdcLedger={pdcLedger} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={"Affiliate"}>
                                                <AffiliateProgram accountDetailsForm={accountDetailsForm} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Membership">
                                                <Membership
                                                    subscriptions={subscriptions}
                                                    membership={membership}
                                                    paymentMethods={paymentMethods}
                                                    setPaymentMethods={setPaymentMethods}
                                                    setLoadingAccountPage={setLoadingAccountPage}
                                                    getMembershipDetails={getMembershipDetails}
                                                    handleSetDefaultPaymentMethod={handleSetDefaultPaymentMethod}
                                                />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Order History">
                                                <OrderHistory
                                                    limit={15}
                                                    setOffset={setOffset}
                                                    offset={offset}
                                                    orderHistory={orderHistory}
                                                    count={orderHistoryCount}
                                                />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Family Tree">
                                                <FamilyTree genealogyTree={genealogyTree} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Matrix">
                                                <Matrix matrixTree={matrixTree} />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Container>
                    )}
                    <ToastContainer
                        position="top-right"
                        autoClose={3000}
                        hideProgressBar={true}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss={false}
                        pauseOnHover={false}
                        theme="light"
                    />
                </>
            )}
        </>
    );
};
