import react, { useEffect, useState } from "react";
import "./membership-info-off-canvas.scss";
import { OffCanvasWrapper } from "../OffCanvasWrapper/OffCanvasWrapper";

interface MembershipInfoOffCanvasProps {
    membershipInfoOffCanvasShow: boolean;
    setMembershipInfoOffCanvasShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MembershipInfoOffCanvas = ({ membershipInfoOffCanvasShow, setMembershipInfoOffCanvasShow }: MembershipInfoOffCanvasProps) => {
    return (
        <OffCanvasWrapper
            offCanvasPlacement="end"
            showOffCanvas={membershipInfoOffCanvasShow}
            setShowOffCanvas={setMembershipInfoOffCanvasShow}
            offCanvasTitle="Sign up for a membership!"
            fullWidth
            offCanvasBody={<div className="row mb-3 g-3">Sign up for a TopShelfNutra Membership....</div>}
        />
    );
};
