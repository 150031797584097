import { useEffect } from "react";
import { useShoppingCart } from "../../context/ShoppingCartContext";

interface SuccessfulPaymentPageeProps {}

export const SuccessfulPaymentPagee = ({}: SuccessfulPaymentPageeProps) => {
    const { clearCart } = useShoppingCart();

    useEffect(() => {
        clearCart();
    }, []);

    return <>Congratuations!</>;
};
