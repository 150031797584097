import { UUID } from "crypto";
import { ApiCaller } from "./ApiCaller";

export interface Product {
    sku: string;
    is_back_ordered: boolean;
    is_available: boolean;
    is_coming_soon: boolean;
    pending_price_update: boolean;
    created: number;
    description: "" | null;
    features: [];
    id: string;
    name: string;
    member_multiple_price: ProductPrice;
    member_single_price: ProductPrice;
    retail_price: ProductPrice;
};

export interface ProductPrice {
    active: boolean;
    created: number;
    currency: string;
    id: string;
    lookup_key: null;
    metadata: {};
    nickname: string;
    product_id: string;
    recurring: null;
    type: string;
    unit_amount: number;
    unit_amount_formatted: string;
}

export class ProductApi {
    private static apiCaller = new ApiCaller("s_product");

    public static GetAll(): Promise<Product[]> {
        return this.apiCaller.GET<Product[]>("products");
    }
}