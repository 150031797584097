import react, { useContext, useEffect, useState } from "react";
import "./mobile-side-navigation-canvas.scss";
import { OffCanvasWrapper } from "../OffCanvasWrapper/OffCanvasWrapper";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive, faCircleUser, faDoorOpen, faHouse, faLayerGroup, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { AuthContext, useAuthenticatedUser } from "../../context/AuthContext";
import { recalculateCartItems, useShoppingCart } from "../../context/ShoppingCartContext";

interface MobileSideNavigationCanvasProps {
    mobileSideNavigationShow: boolean;
    setMobileSideNavigationShow: React.Dispatch<React.SetStateAction<boolean>>;
    setSignInOffCanvasShow: react.Dispatch<react.SetStateAction<boolean>>;
}

export const MobileSideNavigationCanvas = ({
    mobileSideNavigationShow,
    setMobileSideNavigationShow,
    setSignInOffCanvasShow,
}: MobileSideNavigationCanvasProps) => {
    const { authMember, isLoggedIn, logout } = useAuthenticatedUser();
    const { cartItems, setCartItems, allProducts } = useShoppingCart();

    const logoutWithCartConvert = () => {
        if (cartItems.length > 0) {
            recalculateCartItems(false, cartItems, setCartItems, allProducts);
        }
        logout();
        setMobileSideNavigationShow(false);
    };

    return (
        <OffCanvasWrapper
            offCanvasPlacement="start"
            showOffCanvas={mobileSideNavigationShow}
            setShowOffCanvas={setMobileSideNavigationShow}
            offCanvasTitle={`TopShelfNutra`}
            offCanvasBody={
                <div className="pl-2">
                    <ul className="navbar-nav align-items-center ">
                        <li className="mobile-side-navigation-canvas__nav-item py-2 pl-1 nav-item w-100">
                            <Link onClick={() => setMobileSideNavigationShow(false)} to="/" className="d-flex align-items-center nav-link fs-6">
                                <FontAwesomeIcon className="px-3 fs-5" icon={faHouse} />
                                <div className="ml-4">Dashboard</div>
                            </Link>
                        </li>

                        <li className="mobile-side-navigation-canvas__nav-item py-2 pl-1 border-top nav-item w-100">
                            <Link
                                onClick={() => setMobileSideNavigationShow(false)}
                                to="products"
                                className="d-flex align-items-center py-2 nav-link fs-6"
                            >
                                <FontAwesomeIcon className="px-3 fs-5" icon={faLayerGroup} />
                                Product Catalog
                            </Link>
                        </li>
                        {isLoggedIn && (
                            <li className="mobile-side-navigation-canvas__nav-item py-2 pl-1 border-top nav-item w-100">
                                <Link
                                    onClick={() => setMobileSideNavigationShow(false)}
                                    to="account"
                                    className="d-flex align-items-center py-2 nav-link fs-6"
                                >
                                    <FontAwesomeIcon className="px-3 fs-5" icon={faArchive} />
                                    My Account
                                </Link>
                            </li>
                        )}
                        {isLoggedIn && (
                            <li className="mobile-side-navigation-canvas__nav-item py-2 pl-1 border-top nav-item w-100">
                                <a onClick={logoutWithCartConvert} className="cursor-pointer py-2 nav-link fs-6">
                                    <FontAwesomeIcon className="px-3 fs-5" icon={faDoorOpen} />
                                    Log out
                                </a>
                            </li>
                        )}
                        {!isLoggedIn && (
                            <li className="mobile-side-navigation-canvas__nav-item py-2 pl-1 border-top nav-item w-100">
                                <a
                                    onClick={() => {
                                        setSignInOffCanvasShow(true);
                                        setMobileSideNavigationShow(false);
                                    }}
                                    className="cursor-pointer py-2 nav-link fs-6"
                                >
                                    <FontAwesomeIcon className="px-3 fs-5" icon={faCircleUser} />
                                    Sign In
                                </a>
                            </li>
                        )}
                        {!isLoggedIn && (
                            <li className="mobile-side-navigation-canvas__nav-item py-2 pl-1 border-top nav-item w-100">
                                <Link onClick={() => setMobileSideNavigationShow(false)} to="account/signup" className="py-2 nav-link fs-6">
                                    <FontAwesomeIcon className="px-3 fs-5" icon={faUserPlus} />
                                    Sign Up
                                </Link>
                            </li>
                        )}
                        {isLoggedIn && authMember && (
                            <>
                                <li className="mobile-side-navigation-canvas__nav-item py-2 pl-1 border-top nav-item w-100 d-flex justify-content-center">
                                    <b>Logged in as: {authMember.email}</b>
                                </li>
                                <div className="d-flex align-items-center">
                                    <div>Membership:</div>
                                    <div className={`px-1 ${authMember.active ? "tsn-green" : "tsn-red"}`}>
                                        {authMember.active ? "Active" : "Inactive"}
                                    </div>
                                </div>
                            </>
                        )}
                    </ul>
                </div>
            }
            fullWidth
        />
    );
};
