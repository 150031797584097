/**
 * The structure of a successful api response.
 * @template T The type of the data returned by the operation.
 * */
interface SuccessfulApiResponse<T> {
    /** Whether the operation was successful or not. */
    success: true;
    /** The data returned by the operation. */
    results: T;
}

/**
 * The structure of a failed api response.
 * */
interface FailedApiResponse {
    /** Whether the operation was successful or not. */
    success: false;
    /** The exception thrown during the operation. */
    message: string;
}

/**
 * The structure of a generic api response.
 * @template T The type of the data returned by the operation, if successful.
 * */
export type ApiResponse<T> = SuccessfulApiResponse<T> | FailedApiResponse;

type SingleOrArray<T> = T extends boolean ? boolean | boolean[] : T extends any ? T | T[] : never;

/**
 * Represents a api caller to an api controller on the server.
 * */
export class ApiCaller {
    /** The name of the api controller to call */
    private controller: string;
    private url: string;
    private token: string | null;

    /**
     * Initialize a new ApiCaller.
     * @param area The api controller to call
     * @returns A new ApiCaller.
     */
    public constructor(controller: string) {
        this.controller = controller;
        this.url = process.env.REACT_APP_SERVER_URL ?? "";
        this.token = localStorage.getItem("token");
    }

    /**
     * Handles an api response, throwing a serialized exception if there is one.
     * @template T The data returned by the api operation.
     * @param response The response from the api controller.
     */
    private handleApiResponse<T>(response: Response): any {
        try {
            return response.json().then((json: ApiResponse<T>) => {
                return json;
            });
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Execute an HTTP GET operation.
     * @template T The data returned by the api operation.
     * @param endpoint The method of the api controller to call. Makes up the endpoint of the url.
     * @param queryParameters Parameters to make up the query string in the URL.
     */
    public async GET<T>(endpoint: string, queryParameters?: Record<string, SingleOrArray<number | boolean | string> | null>): Promise<T> {
        let url = `${this.url}/${this.controller}/${endpoint}`;
        if (queryParameters) {
            const parameterStrings: string[] = [];
            for (const key of Object.keys(queryParameters)) {
                const value = queryParameters[key];
                if (value === null) {
                    continue;
                }
                const encodedKey = encodeURIComponent(key);
                if (Array.isArray(value)) {
                    const parameterString = value.map((element) => `${encodedKey}=${encodeURIComponent(element)}`).join("&");
                    parameterStrings.push(parameterString);
                } else {
                    parameterStrings.push(`${encodedKey}=${encodeURIComponent(value)}`);
                }
            }
            if (parameterStrings.length > 0) {
                url += "?" + parameterStrings.join("&");
            }
        }
        if (this.token === null) {
            this.token = localStorage.getItem("token");
        }
        let headers = { Authorization: `Bearer ${this.token}`, Origin: this.url, 'Access-Control-Allow-Origin': this.url };
        return fetch(url, { headers }).then((response) => this.handleApiResponse<T>(response));
    }

    /**
     * Execute an HTTP POST operation.
     * @template T The data returned by the api operation.
     * @param endpoint The method of the api controller to call. Makes up the endpoint of the url.
     * @param data Data to put in the body of the request as a JSON string.
     */
    public POST<T>(endpoint: string, data: any) {
        if (this.token === null) {
            this.token = localStorage.getItem("token");
        }
        return fetch(`${this.url}/${this.controller}/${endpoint}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.token}`,
                Origin: this.url,
                'Access-Control-Allow-Origin': this.url
            },
            body: JSON.stringify(data),
        }).then((response) => this.handleApiResponse<T>(response));
    }

    /**
     * Execute an HTTP POST operation.
     * @template T The data returned by the api operation.
     * @param endpoint The method of the api controller to call. Makes up the endpoint of the url.
     * @param data Data to put in the body of the request as a JSON string.
     */
    public PATCH<T>(endpoint: string, data: any) {
        if (this.token === null) {
            this.token = localStorage.getItem("token");
        }
        return fetch(`${this.url}/${this.controller}/${endpoint}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.token}`,
                Origin: this.url,
                'Access-Control-Allow-Origin': this.url
            },
            body: JSON.stringify(data),
        }).then((response) => this.handleApiResponse<T>(response));
    }

    /**
     * Execute an HTTP PUT operation.
     * @template T The data returned by the api operation.
     * @param endpoint The method of the api controller to call. Makes up the endpoint of the url.
     * @param data Data to put in the body of the request as a JSON string.
     */
    public async PUT<T>(endpoint: string, data: any) {
        if (this.token === null) {
            this.token = localStorage.getItem("token");
        }
        return await fetch(`${this.url}/${this.controller}/${endpoint}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.token}`,
                Origin: this.url,
                'Access-Control-Allow-Origin': this.url
            },
            body: JSON.stringify(data),
        }).then((response) => this.handleApiResponse<T>(response));
    }

    /**
     * Execute an HTTP DELETE operation.
     * @template T The data returned by the api operation.
     * @param endpoint The method of the api controller to call. Makes up the endpoint of the url.
     * @param data Data to put in the body of the request as a JSON string.
     */
    public async DELETE<T>(endpoint: string, data: any) {
        if (this.token === null) {
            this.token = localStorage.getItem("token");
        }
        return await fetch(`${this.url}/${this.controller}/${endpoint}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.token}`,
                Origin: this.url,
                'Access-Control-Allow-Origin': this.url
            },
            body: JSON.stringify(data),
        }).then((response) => this.handleApiResponse<T>(response));
    }
}
