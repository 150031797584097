import { useEffect } from "react";
import "./matrix.scss";
import { Container, Row } from "react-bootstrap";

interface MatrixProps {
    matrixTree: any[];
}

export const Matrix = ({ matrixTree }: MatrixProps) => {
    useEffect(() => {
        TreeData(matrixTree, "#wrapperTree");
    }, []);

    function TreeData(data: any, select: any) {
        var main = document.querySelector("#wrapperMatrixMainContainer");
        if (!document.querySelector(".treeMatrix")) {
            var treecanvas = document.createElement("div");
            treecanvas.className = "treeMatrix";
            if (data.length > 0) {
                var treeCode = buildTree(data, data[0]);
                treecanvas.innerHTML = treeCode;
                if (main) {
                    main.appendChild(treecanvas);
                }
            }
        }
    }

    function buildTree(obj: any, node: any) {
        var _name = node.first_name;
        let iconBadge = '<span class="badge bg-warning" title="Signed Up">S</span>';
        _name = _name + " " + node.last_name;
        if (_name.includes("null")) {
            _name = "<span class='empty'>(empty)</span>";
        }
        var _titleString = node.email;
        var _classString = "";
        if (node.parent_id == null) _classString = "root ";
        var _nodeBadge = "";

        var treeString =
            '<li class="" title="' +
            _titleString +
            '"><a class="cursor-default fs-6 generic-member-node ' +
            _classString +
            '" data-MemberId="' +
            node.member_id +
            '" role="button">' +
            _name +
            " " +
            _nodeBadge +
            '<div class="mt-1">(' +
            node.display_id +
            ")</div></a>";
        treeString = treeString.replace("(null)", "");

        var _children = [];
        for (var i in obj) {
            if (obj[i].parent_id == node.member_id) _children.push(obj[i]);
        }
        if (_children.length > 0) {
            treeString += "<ul>";
            for (var i in _children) {
                treeString += buildTree(obj, _children[i]);
            }
            treeString += "</ul>";
        }
        return treeString;
    }

    return (
        <>
            <Container fluid className="mt-3 mt-sm-3 mt-md-3 mt-lg-0 mb-5 wrapper-tab wrapper-affiliate d-block family-tree-container">
                <Row className="py-6 wrapper-details">
                    <span className="fs-4 mb-4 fw-bold">Matrix</span>
                </Row>

                {matrixTree.length > 0 ? (
                    <div id="wrapperMatrixMainContainer" className="mt-3 border-top wrapper-unilevel-tree ps-2 pe-2 pt-2 pb-0 d-block">
                        <div id="wrapperTree" className="wrapper-tree mt-2 pt-4"></div>
                    </div>
                ) : (
                    <h5>Sorry, the matrix hasn't been ran yet. Please try again later.</h5>
                )}
            </Container>
        </>
    );
};
