export const checkTokenExpiration = (logout: any) => {
    let JWT = localStorage.getItem("token");
    if (JWT) {
        const token_expiration_UTC = JSON.parse(window.atob(JWT.split(".")[1]));
        const right_now = Math.floor(new Date().getTime() / 1000);
        if (token_expiration_UTC.exp < right_now) {
            logout();
            return true;
        }
    }
    return false;
};

export const insertDecimalPlace = (num: number) => {
    return (num / 100).toFixed(2);
};

export const showHeaderAndFooter = (pathName: string) => {
    if (pathName.includes("/account/verify")) {
        document.getElementById("main-header")?.classList.add("d-none");
        document.getElementById("main-footer")?.classList.add("d-none");
    } else {
        document.getElementById("main-header")?.classList.remove("d-none");
        document.getElementById("main-footer")?.classList.remove("d-none");
    }
};
