import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { BootstrapInput } from "../BootstrapInput/BootstrapInput";

type CAPropositionProps = {
    isChecked: boolean;
    setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CAProposition = ({ isChecked, setIsChecked }: CAPropositionProps) => {
    return (
        <>
            <div className="p-2 border d-flex flex-column justify-content-center">
                <div className="d-flex justify-content-center align-items-center gap-2">
                    <FontAwesomeIcon style={{ color: "rgb(230, 170, 4)" }} className="fs-3" icon={faTriangleExclamation} />
                    <div className="fs-5 mt-1" style={{ color: "rgb(230, 170, 4)" }}>
                        <b>Warning</b>
                    </div>
                </div>
                <div className="mb-4">
                    California Proposition 65 advisory: These products can expose you to chemicals including lead, which is known to the State of
                    California to cause cancer and birth defects or other reproductive harm. For more information, go to www.p65warnings.ca.gov
                </div>
            </div>
            <div className="my-3 mx-3">
                <BootstrapInput onChange={() => setIsChecked(!isChecked)} className="mt-4" type="checkbox" value={isChecked} label="I understand the risks" />
            </div>
        </>
    );
};
