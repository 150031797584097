import React, { ReactNode } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./bootstrap-dropdown.scss";

interface BoostrapDropdownProps {
    label?: string;
    dropdownItems: MenuItem[];
    onClick?: any;
    value: string;
}

export interface MenuItem {
    title: string;
    icon?: ReactNode;
    id?: number;
    to?: string;
    onClick?: () => void;
    hasDivider?: boolean;
}

export const BootstrapDropdown = ({ label, dropdownItems, onClick, value }: BoostrapDropdownProps) => {
    const handleOnClick = (id: number, onClick: any) => {
        onClick(id);
    };

    return (
        <>
            {label && <Form.Label className="bootstrap-input__label">{label}</Form.Label>}
            <Dropdown className="bootstrap-dropdown">
                <Dropdown.Toggle className="shadow-none bootstrap-dropdown__btn">{value}</Dropdown.Toggle>

                <Dropdown.Menu>
                    {dropdownItems.map((menuItem) => (
                        <React.Fragment key={menuItem.title}>
                            {menuItem.hasDivider && <Dropdown.Divider />}
                            <Dropdown.Item
                                className="bootstrap-dropdown__item d-flex align-items-center"
                                onClick={() =>
                                    onClick && menuItem.id ? handleOnClick(menuItem.id, onClick) : menuItem.onClick && menuItem.onClick()
                                }
                            >
                                {menuItem.icon && menuItem.icon}
                                {menuItem.to ? (
                                    <Link className="bootstrap-dropdown__link" to={menuItem.to}>
                                        {menuItem.title}
                                    </Link>
                                ) : (
                                    menuItem.title
                                )}
                            </Dropdown.Item>
                        </React.Fragment>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};
