import { UUID } from "crypto";
import { ApiCaller } from "./ApiCaller";

export class QualificationApi {
    private static apiCaller = new ApiCaller("qualification");

    public static GetMatrixMemberTable(table_month: string, table_year: string, member_id: UUID): Promise<any[]> {
        return this.apiCaller.GET<any[]>("getMatrixMemberTable", { table_name: `matrix_${table_month}_${table_year}`, member_id });
    }
}
