import react, { useState } from "react";
import { Product } from "../../../apis/ProductApi";
import { BootstrapButton } from "../../../components/BootstrapButton/BootstrapButton";
import { useShoppingCart } from "../../../context/ShoppingCartContext";
import { useAuthenticatedUser } from "../../../context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import "./product-item.scss";
import { Link } from "react-router-dom";

interface ProductItemProps {
    product: Product;
    setMembershipInfoOffCanvasShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProductItem = ({ product, setMembershipInfoOffCanvasShow }: ProductItemProps) => {
    const { getItemQuantity, increaseCartQuantity, decreaseCartQuantity, removeFromCart } = useShoppingCart();
    const { authMember } = useAuthenticatedUser();
    const quantity = getItemQuantity(product.id);
    const [imageIsValid, setImageIsValid] = useState(true);

    return (
        <div key={product.name} className="col col-container container-product-image">
            <div className="card-wrapper card card-product h-100">
                <div className="card-body product-item__card_tsn_body">
                    <Link style={{ textUnderlineOffset: "5px", color: "inherit" }} to={`/products/${product.id}`}>
                        <div className="text-center position-relative">
                            {imageIsValid ? (
                                <img
                                    src={`/${product.id}_label.JPG`}
                                    style={{ height: "300px", objectFit: "contain" }}
                                    className="card-image w-100 mb-3 img-fluid object-fit-contain border rounded"
                                    onError={() => {
                                        setImageIsValid(false);
                                    }}
                                />
                            ) : (
                                <img
                                    src={"https://topshelfnutra.com/main.svg"}
                                    style={{ height: "300px", objectFit: "contain" }}
                                    className="card-image w-100 mb-3 img-fluid object-fit-contain border rounded"
                                />
                            )}
                        </div>
                        <div className="card-title fs-5 fw-bold text-center mb-1">{product.name}</div>
                    </Link>
                    {product.member_single_price ? (
                        <div className="d-flex flex-column justify-content-between align-items-start mt-3">
                            {!authMember?.active && (
                                <div>
                                    <div className="fs-6 fw-bold mb-2">
                                        Retail:
                                        <span className="product-pricing ms-1">{product.retail_price.unit_amount_formatted}</span>
                                    </div>
                                </div>
                            )}
                            <div className="d-flex">
                                <div className="fs-6 fw-bold mb-2">Member:</div>
                                <div className="d-flex flex-column ms-2">
                                    <div className="fs-6 fw-bold mb-2">
                                        {authMember && authMember.active && "First:"}
                                        <span className={`${authMember && authMember.active ? "product-pricing ms-1" : ""}`}>
                                            {product.member_single_price.unit_amount_formatted}{" "}
                                            {/* {!authMember?.active && (
                                                <FontAwesomeIcon
                                                    onClick={() => setMembershipInfoOffCanvasShow(true)}
                                                    className="fs-5 product-item__info"
                                                    icon={faInfoCircle}
                                                />
                                            )} */}
                                        </span>
                                    </div>
                                    {authMember && authMember.active && (
                                        <div className="fs-6 fw-bold mb-2">
                                            Each after:
                                            <span className="product-pricing ms-1">{product.member_multiple_price.unit_amount_formatted}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="d-flex flex-column justify-content-between align-items-center mt-3">
                            <div>Pricing Coming Soon</div>
                        </div>
                    )}
                    <div className="d-flex align-items-center justify-content-between mt-3" style={{ gap: ".5rem" }}>
                        {quantity === 0 ? (
                            <>
                                {product.is_coming_soon && <div className="product-item__out_of p-2 px-3">Coming Soon</div>}
                                {!product.is_coming_soon && product.is_back_ordered && (
                                    <div className="product-item__out_of p-2 px-3">Out of Stock</div>
                                )}
                                {!product.is_back_ordered && !product.is_coming_soon && (
                                    <BootstrapButton onClick={() => increaseCartQuantity(product)} variant="secondary" title="Add to Cart" />
                                )}
                            </>
                        ) : (
                            <div className="d-flex align-items-center justify-content-center" style={{ gap: ".5rem" }}>
                                <BootstrapButton onClick={() => decreaseCartQuantity(product.id)} variant="secondary" title="-" />
                                <div className="fs-6">
                                    <b>
                                        <span className="fs-5">{quantity}</span>
                                    </b>
                                </div>
                                <BootstrapButton
                                    disabled={quantity === 10}
                                    onClick={() => increaseCartQuantity(product)}
                                    variant="secondary"
                                    title="+"
                                />
                            </div>
                        )}
                        {quantity > 0 && <BootstrapButton onClick={() => removeFromCart(product.id)} variant="danger" title="Remove" />}
                    </div>
                </div>
            </div>
        </div>
    );
};
