import react, { useEffect, useState } from "react";
import "./password-reset-off-canvas.scss";
import { OffCanvasWrapper } from "../OffCanvasWrapper/OffCanvasWrapper";
import { BootstrapInput } from "../BootstrapInput/BootstrapInput";
import { BootstrapButton } from "../BootstrapButton/BootstrapButton";
import { AuthApi } from "../../apis/AuthApi";
import { passMeetsRequirements } from "../../utils/string";
import { useParams } from "react-router-dom";

interface PasswordResetOffCanvasProps {
    passwordResetOffCanvasShow: boolean;
    setPasswordResetOffCanvasShow: React.Dispatch<React.SetStateAction<boolean>>;
    setSignInOffCanvasShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PasswordResetOffCanvas = ({
    passwordResetOffCanvasShow,
    setPasswordResetOffCanvasShow,
    setSignInOffCanvasShow,
}: PasswordResetOffCanvasProps) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [resetPasswordEmail, setResetPasswordEmail] = useState("");
    const [authToken, setAuthToken] = useState("");
    const [passwordInput, setPasswordInput] = useState("");
    const [passwordInputValid, setPasswordInputValid] = useState(true);
    const [inputValid, setInputValid] = useState(true);
    const [inputSuccess, setInputSuccess] = useState(false);
    const [isComingFromEmail, setIsComingFromEmail] = useState(false);

    useEffect(() => {
        if (window.location.search.includes("pr=true")) {
            setIsComingFromEmail(true);
            let splitSearchUrl = window.location.search.split("&");
            splitSearchUrl.forEach((searchParam) => {
                if (searchParam.includes("email")) {
                    setResetPasswordEmail(searchParam.split("=")[1]);
                }
                if (searchParam.includes("token")) {
                    setAuthToken(searchParam.split("=")[1]);
                }
            });
        }
    }, []);

    const requestPasswordResetClick = async () => {
        let validPasswordResetInput = resetPasswordEmail.length > 0 && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(resetPasswordEmail);
        setInputValid(true);
        setErrorMessage("");

        if (validPasswordResetInput) {
            let response = await AuthApi.ResetPasswordUnauthorized(resetPasswordEmail.toLowerCase());
            if (response.success) {
                setInputSuccess(true);
                setTimeout(() => {
                    setPasswordResetOffCanvasShow(false);
                }, 3000);
            } else {
                setErrorMessage("The email you have provided doesn't exist in our system.");
                setInputValid(false);
            }
        } else {
            setInputValid(false);
        }
    };

    const saveNewPassword = async () => {
        let validPasswordInput = passwordInput.length > 0 && passMeetsRequirements(passwordInput);
        let validPasswordResetInput = resetPasswordEmail.length > 0 && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(resetPasswordEmail);
        setPasswordInputValid(true);

        if (validPasswordInput && validPasswordResetInput && authToken.length > 0) {
            let response = await AuthApi.ResetPasswordUnauthorized(resetPasswordEmail.toLowerCase(), passwordInput, authToken).catch(console.error);
            if (response.success) {
                setInputSuccess(true);
                setTimeout(() => {
                    setPasswordResetOffCanvasShow(false);
                    setSignInOffCanvasShow(true);
                }, 3000);
            }
        } else {
            setPasswordInputValid(false);
        }
    };

    return (
        <OffCanvasWrapper
            offCanvasPlacement="start"
            showOffCanvas={passwordResetOffCanvasShow}
            setShowOffCanvas={setPasswordResetOffCanvasShow}
            offCanvasTitle="Password Reset"
            fullWidth
            offCanvasBody={
                <div className="row mb-3 g-3">
                    <p className="py-6 fs-6 fw-bold text-center">
                        {isComingFromEmail
                            ? "Enter your new password in the field below and click the 'Save Password' button."
                            : "A link will be sent to your email with instructions on completing the reset password process."}
                    </p>
                    {errorMessage.length > 0 && <div className="col-12 tsn-red">{errorMessage}</div>}
                    <div className="col-12">
                        {isComingFromEmail ? (
                            <BootstrapInput
                                valid={passwordInputValid}
                                value={passwordInput}
                                onChange={(e) => setPasswordInput(e.target.value)}
                                label="New Password"
                                type="password"
                            />
                        ) : (
                            <BootstrapInput
                                valid={inputValid}
                                value={resetPasswordEmail}
                                onChange={(e) => setResetPasswordEmail(e.target.value)}
                                label="Email"
                            />
                        )}
                    </div>
                    <div className="d-grid my-9">
                        {isComingFromEmail && (
                            <div className="col-12 mt-2 me-4 mb-4 text-center">
                                <div className="fs-6 fw-lighter ">
                                    {" "}
                                    <span className="fw-bold">Password requirements:</span> minimum 8 characters, at least one uppercase letter, one
                                    number, and one special character.
                                </div>
                            </div>
                        )}
                        <div className="col-12 d-grid">
                            {isComingFromEmail ? (
                                <BootstrapButton
                                    showSuccess={{ show: inputSuccess, message: "Successfully Reset Password" }}
                                    variant="secondary"
                                    title="Save Password"
                                    onClick={saveNewPassword}
                                />
                            ) : (
                                <BootstrapButton
                                    showSuccess={{ show: inputSuccess, message: "Sent Password Reset Email!" }}
                                    variant="secondary"
                                    title="Request Password Reset"
                                    onClick={requestPasswordResetClick}
                                />
                            )}
                        </div>
                    </div>
                </div>
            }
        />
    );
};
