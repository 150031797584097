import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Offcanvas, Row } from "react-bootstrap";
import "./offCanvasWrapper.scss";

type OffCanvasWrapperProps = {
    showOffCanvas: boolean;
    setShowOffCanvas: React.Dispatch<React.SetStateAction<boolean>>;
    offCanvasTitle: string;
    offCanvasBody: React.ReactElement;
    offCanvasPlacement?: "start" | "end" | "bottom" | "top";
    fullWidth?: boolean;
};

export const OffCanvasWrapper = ({
    showOffCanvas,
    setShowOffCanvas,
    offCanvasTitle,
    offCanvasBody,
    offCanvasPlacement = "start",
    fullWidth = false,
}: OffCanvasWrapperProps) => {
    const [windowDemension, setWindowDemension] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    });
    const [changedFullWidth, setChangedFullWidth] = useState(fullWidth);

    const detectSize = useCallback(() => {
        setWindowDemension({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        });
    }, []);

    useEffect(() => {
        if (windowDemension.winWidth > 750) {
            setChangedFullWidth(false);
        } else {
            setChangedFullWidth(true);
        }
        window.addEventListener("resize", detectSize);
        return () => {
            window.removeEventListener("resize", detectSize);
        };
    }, [windowDemension]);

    return (
        <>
            <Offcanvas
                autoFocus
                className={`${offCanvasPlacement === "top" || offCanvasPlacement === "bottom" ? "" : "offcanvas-main"} ${
                    changedFullWidth ? "offcanvas-full" : ""
                }`}
                scroll={false}
                placement={offCanvasPlacement}
                show={showOffCanvas}
                onHide={() => setShowOffCanvas(false)}
            >
                <Offcanvas.Header className="border-bottom" closeButton>
                    <Offcanvas.Title>{offCanvasTitle}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="position-relative">
                    {offCanvasBody}
                    {/* <button onClick={() => setShowOffCanvas(false)} type="button" className="other-back-btn btn-close" aria-label="Close"></button> */}
                </Offcanvas.Body>
            </Offcanvas>
            {showOffCanvas && <div onClick={() => setShowOffCanvas(false)} className="offcanvas-backdrop fade show back-drop"></div>}
        </>
    );
};
