import react, { useState } from "react";
import "./privacy-policy-off-canvas.scss";
import { OffCanvasWrapper } from "../OffCanvasWrapper/OffCanvasWrapper";

interface PrivacyPolicyOffCanvasProps {
    privacyPolicyOffCanvasShow: boolean;
    setPrivacyPolicyOffCanvasShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PrivacyPolicyOffCanvas = ({ privacyPolicyOffCanvasShow, setPrivacyPolicyOffCanvasShow }: PrivacyPolicyOffCanvasProps) => {
    let data = `
    <p style="margin-bottom:14pt; line-height:normal">
        <span style="font-family:Arial; color:#212529">TopShelfNutra is committed to top customer service and we intend to uphold this by providing a private and secure browsing experience. The following policy will help you to understand how we will treat your personal information. As we continue to improve our site and as we take advantage of developments in technology, this policy may change from time to time so please check this policy on an ongoing basis for revisions and updates.</span>
    </p>
    <h5>Information Collection and Use</h5>
    <p style="margin-bottom:14pt; line-height:normal">
        <span style="font-family:Arial; color:#212529">TopShelfNutra collects information from our web site in several ways. During registration, TopShelfNutra asks for information such as your name, phone number, and email address. Once you have submitted this information and chosen a valid password, you will be able to take full advantage of everything TopShelfNutra has to offer. You may also be asked for personal information when you report a problem with our site, or services; or, if you otherwise contact TopShelfNutra. TopShelfNutra also collects information using cookies, which are small pieces of information that are stored by your browser on your computer's hard drive. This information allows us to automatically link our customers to their personalized accounts. Most web browsers automatically accept cookies. Please check your browser's guidelines should you wish to prevent cookies.</span>
    </p>
    <p style="margin-bottom:14pt; line-height:normal">
        <span style="font-family:Arial; color:#212529">TopShelfNutra may use individual information for any legally permissible purpose in company’s sole discretion. This includes providing promotional offers to individuals by means of email advertising, telephone marketing, direct mail marketing, among other possible uses. TopShelfNutra also has the right to sell or otherwise distribute individual contact information to third parties for marketing or other purposes.</span>
    </p>
    <p style="margin-bottom:14pt; line-height:normal">
        <span style="font-family:Arial; color:#212529">If you do not wish us to use your personal information to promote or sell our products and services, or to sell, transfer, or otherwise provide personal information about you to third parties, you may inform us anytime by emailing customer service at</span><span style="font-family:Arial; color:#212529">&nbsp;</span><a href="mailto:support@topshelfnutra.com" style="text-decoration:none"><u><span style="font-family:Arial; color:#007bff">support@topshelfnutra.com</span></u></a><span style="font-family:Arial; color:#212529">&nbsp;</span><span style="font-family:Arial; color:#212529">and we will honor your request.</span>
    </p>
    <p>
        &nbsp;
    </p>`;
    return (
        <OffCanvasWrapper
            offCanvasPlacement="end"
            showOffCanvas={privacyPolicyOffCanvasShow}
            setShowOffCanvas={setPrivacyPolicyOffCanvasShow}
            offCanvasTitle="Privacy Policy"
            offCanvasBody={
                <section className="my-lg-14 my-8">
                    <div dangerouslySetInnerHTML={{ __html: data }} />
                </section>
            }
            fullWidth
        />
    );
};
