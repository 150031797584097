import { ApiCaller } from "./ApiCaller";

export interface Subscription {
    active: boolean;
    created: number;
    id: string;
    description: string;
    images: string[];
    name: string;
    type: string;
    default_price: string;
    metadata?: {
        general_product: "false" | undefined;
        is_yearly: "true" | undefined;
    };
}

export class StripeApi {
    private static billingCaller = new ApiCaller("billing");
    private static customerCaller = new ApiCaller("customer");

    public static GetActiveSubscriptions() {
        return this.billingCaller.GET(`/subscriptions/`);
    }

    public static CancelSubscription(subscription_id: string, member_id: string) {
        return this.customerCaller.POST<{ success: boolean; message: string }>(`cancelSubscription/`, { subscription_id, member_id });
    }
}
