import React, { useEffect, useState } from "react";
import "./verify-page.scss";
import Logo from "../../assets/images/logo/main.svg";
import { BootstrapButton } from "../../components/BootstrapButton/BootstrapButton";
import { AuthApi } from "../../apis/AuthApi";
import { Loader } from "../../components/Loader/Loader";
import { SignInOffCanvas } from "../../components/SignInOffCanvas/SignInOffCanvas";
import { PasswordResetOffCanvas } from "../../components/PasswordResetOffCanvas/PasswordResetOffCanvas";
import { useLocation, useNavigate } from "react-router-dom";

interface VerifyPageProps {}

export const VerifyPage = ({}: VerifyPageProps) => {
    const [verifyingAccount, setVerifyingAccount] = useState(true);
    const [accountIsVerified, setAccountIsVerified] = useState(false);
    const [signInOffCanvasShow, setSignInOffCanvasShow] = useState(false);
    const [passwordResetOffCanvasShow, setPasswordResetOffCanavasShow] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [error, setError] = useState("");

    useEffect(() => {
        let verification_id = location.search.replace("?", "");
        if (verification_id && verification_id.length > 0) {
            AuthApi.VerifyEmail(verification_id)
                .then((response) => {
                    if (response.success) {
                        setAccountIsVerified(true);
                    } else {
                        setAccountIsVerified(false);
                    }
                })
                .catch(console.error)
                .finally(() => setVerifyingAccount(false));
        } else {
            setVerifyingAccount(false);
            setError("Something unexpected happened...");
        }
    }, []);

    return (
        <>
            <SignInOffCanvas
                signInOffCanvasShow={signInOffCanvasShow}
                setSignInOffCanvasShow={setSignInOffCanvasShow}
                setPasswordResetOffCanvasShow={setPasswordResetOffCanavasShow}
            />
            <PasswordResetOffCanvas
                passwordResetOffCanvasShow={passwordResetOffCanvasShow}
                setPasswordResetOffCanvasShow={setPasswordResetOffCanavasShow}
                setSignInOffCanvasShow={setSignInOffCanvasShow}
            />
            {verifyingAccount ? (
                <Loader />
            ) : (
                <div className="page-content">
                    <div className="d-flex  d-block d-md-none ">
                        <div className="d-flex mt-5 ">
                            <img src={"https://topshelfnutra.com/main.svg"} alt="" className="img-fluid mx-auto" style={{ width: "150px" }} />
                        </div>
                    </div>

                    <div className="d-flex d-none d-md-block mx-auto">
                        <div className="d-flex mt-5 ">
                            <img src={"https://topshelfnutra.com/main.svg"} alt="" className="img-fluid mx-auto" />
                        </div>
                    </div>

                    <div className="mt-5 p-4">
                        {error.length > 0 ? (
                            <div className="d-flex justify-content-center">{error}</div>
                        ) : (
                            <div className="d-flex flex-column">
                                <div className="mx-auto">
                                    <div className="fs-3 fw-bold">{accountIsVerified ? "Account Verified" : "Verification Failed"}</div>
                                </div>
                                <div className="mx-auto mt-4">
                                    <div className="fs-3  fw-bold">
                                        {accountIsVerified
                                            ? "You can now sign-in to your account to configure your member subscription"
                                            : "An error has occured while trying to verify you account."}
                                    </div>
                                </div>
                                {accountIsVerified ? (
                                    <div className="mx-auto mt-4">
                                        <div className="fs-3 fw-bold">
                                            <BootstrapButton
                                                onClick={() => setSignInOffCanvasShow(true)}
                                                className="p-2 px-3"
                                                variant="secondary"
                                                title="Sign-In to My Account"
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="mx-auto mt-4">
                                        <BootstrapButton
                                            onClick={() => navigate("/")}
                                            className="p-2 px-3"
                                            variant="secondary"
                                            title="Go to Dashboard"
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
