import { faDollarSign, faStar, faTruck, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Delivery from "../../assets/images/backgrounds/delivery.jpg";
import { BootstrapButton } from "../../components/BootstrapButton/BootstrapButton";
import { useAuthenticatedUser } from "../../context/AuthContext";
import "./landing-page.scss";
import { AuthApi } from "../../apis/AuthApi";

interface LandingPageProps {
    setSignInOffCanvasShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LandingPage = ({ setSignInOffCanvasShow }: LandingPageProps) => {
    const { isLoggedIn } = useAuthenticatedUser();
    const [affiliatePersonExists, setAffiliatePersonExists] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        handleVerifyAffiliateLink();
    }, []);

    const handleVerifyAffiliateLink = () => {
        const currentUrl = window.location;
        let path = currentUrl.pathname;
        path = path.replace("/account/signup", "");
        path = path.replace("/account/signup/", "");
        path = path.replace("/", "");

        if (path.length === 6) {
            AuthApi.VerifyParentDisplayId(path)
                .then((response) => {
                    setAffiliatePersonExists(response.parent_name);
                })
                .catch(console.error);
        }
    };

    return (
        <div className="landing-page">
            <div className="mb-4 rounded-3 w-100">
                <div className="d-flex flex-column justify-content-center align-items-center container-fluid landing-page__landing_hero py-5 hero">
                    <h1 className="display-5 fw-bold">Welcome to TopShelfNutra</h1>
                    <p className=" fs-4"> All of your favorite nutritionals at a price that makes you smile!</p>
                    {affiliatePersonExists.length > 0 && (
                        <>
                            <Container className="d-none d-lg-block">
                                <div className="d-flex mt-4 w-100 d-block">
                                    <div className="alert alert-info lb-welcome fs-5" role="alert">
                                        Looks like <b>{affiliatePersonExists}</b> wanted you to look around. Take a walk through the product catalog
                                        and when you are ready and want to be part of the family, click the <b>Become a Member</b> button and follow
                                        the simple three step process.
                                    </div>
                                </div>
                            </Container>
                            <Container className="mt-4 d-block d-lg-none">
                                <div className="d-flex mt-4 w-100 d-block">
                                    <div className="alert alert-info lb-welcome" role="alert">
                                        Looks like <b>{affiliatePersonExists}</b> wanted you to look around. Take a walk through the product catalog
                                        and when you are ready and want to be part of the family, click the <b>Become a Member</b> button and follow
                                        the simple three step process.
                                    </div>
                                </div>
                            </Container>
                        </>
                    )}
                    {!isLoggedIn && (
                        <>
                            <div>
                                <BootstrapButton
                                    onClick={() => navigate("/account/signup/")}
                                    className="btn-lg"
                                    title="Become a Member for only $14.95 monthly"
                                    variant="secondary"
                                />
                                <div>to access member pricing and FREE SHIPPING!</div>
                            </div>
                            <a onClick={() => setSignInOffCanvasShow(true)} className="mt-3 tsn-white cursor-pointer">
                                Already a member? Sign In
                            </a>
                        </>
                    )}
                </div>
            </div>

            <Container className="p-4 mt-5 bg-body-landing rounded-3">
                <h1 className="display-5 fw-bold">Why TopShelfNutra?</h1>
                <p className="fs-5 mt-3">
                    Many health programs and products we love have been priced out of our budgets. Also, to save money, suppliers are regularly
                    changing their formulas to cheaper and inferior ingredients, resulting in less positive results at the same dosages. On top of
                    these challenges, shipping charges continue to increase and add even more cost to our orders.
                </p>
                <p className="fs-5 mt-2">
                    TopShelfNutra solves these and many more problems. We have been formulating, manufacturing, and distributing high-end health
                    products around the globe for over 30 years. We know first-hand from our many customers which products work and for whom and for
                    what conditions. We are so excited to offer these amazing formulas that have been changing lives for decades at prices never seen
                    before! Finally, the entire family can have access to the finest nutrition at pricing that used to be only for an individual.
                </p>
                <p className="fs-5 mt-2">
                    How do we offer such amazing value to our customers? The answer is manifold. We have larger purchasing power. We produce our
                    products in larger quantities and in streamlined and less expensive packaging. We are the manufacturer and ship directly to the
                    consumer. We have negotiated lower shipping rates and so your{" "}
                    <span className="fw-bold">monthly subscription grants you unlimited FREE Shipping</span>. We keep our overhead expenses low and
                    have no middlemen. Instead of hefty advertising and marketing budgets, we rely on “word of mouth” referrals from our happy
                    customers.
                </p>
                <p className="fs-5 mt-2">
                    In the end, it is an improved model. You get better quality, better results, better value, and better convenience. Order what you
                    want, when you want it, delivered to where you want to receive it. Refer your friends and family and earn Product Discount Credits
                    every month that can lower your prices even more!
                </p>
            </Container>
            <Container className="mt-5">
                <Row>
                    <Col className="col-12 ">
                        <Row className="">
                            <Col md={6} className="p-0 col-12">
                                <div className="landing-page__card-height p-3 m-2 tsn-black-background">
                                    <div className="landing-page__icon-wrapper py-1 px-2 feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3 tsn-feature-icon">
                                        <FontAwesomeIcon className="fs-4" icon={faDollarSign} />
                                    </div>
                                    <h4 className="my-3 fw-semibold mb-0 text-body-emphasis">Best Prices</h4>
                                    <p className="mt-3 text-body-secondary">
                                        As a subscribed member of TopShelfNutra, you benefit from our larger purchasing power. Save more by buying
                                        more! Never skimp on quality or quantity. Finally, you have affordable TopShelf nutrition for the entire
                                        family!
                                    </p>
                                </div>
                            </Col>

                            <Col md={6} className="p-0 col-12">
                                <div className="landing-page__card-height border m-2 p-3">
                                    <div className="landing-page__icon-wrapper py-1 px-2 feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3 tsn-feature-icon">
                                        <FontAwesomeIcon className="fs-4" icon={faStar} />
                                    </div>
                                    <h4 className="my-3 fw-semibold mb-0 text-body-emphasis">Highest Quality!</h4>
                                    <p className="mt-3 text-body-secondary">
                                        TopShelf Nutra products offer unique formulas using the finest available ingredients and manufactured under
                                        the strictest care. We work with only certified manufacturers who guarantee both quality and purity.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container className="mt-6 mb-5">
                <Row className=" bg-body-landing border-radius row-cols-1 row-cols-md-2 g-0 g-md-5">
                    <Col className="p-4">
                        <h1 className="display-5 fw-bold">Refer your Friends</h1>
                        <div className="d-flex">
                            <p className=" fs-4">
                                {" "}
                                TopShelfNutra is a family. We encourage and reward sharing the savings and benefits of our products and program with
                                others. Each time you refer a friend who subscribes, both you and your friend will receive product discount credits
                                deposited into your accounts. These credits can be applied to any future product purchase. Refer more and save more!
                            </p>
                        </div>
                        {!isLoggedIn && (
                            <>
                                <div>
                                    <BootstrapButton
                                        onClick={() => navigate("/account/signup/")}
                                        className="btn-lg"
                                        title="Become a Member for only $14.95 monthly"
                                        variant="secondary"
                                    />
                                    <div>to access member pricing and FREE SHIPPING!</div>
                                </div>
                            </>
                        )}
                    </Col>
                    <Col className="d-flex flex-column align-items-start gap-2">
                        <img src={Delivery} className="img-fluid rounded-3" alt="Delivery" />
                    </Col>
                </Row>
            </Container>
            <Container className="mt-4 mb-5">
                <Row className=" bg-body-landing border-radius">
                    <Col className="p-4">
                        <h1 className="display-5 fw-bold">Refund Policy</h1>
                        <div className="d-flex">
                            <div className="d-flex flex-column">
                                <h5>For Product Purchases:</h5>
                                <p style={{ marginBottom: "14pt", lineHeight: "normal" }}>
                                    <span>
                                        All TopShelfNutra products have a 30 day guarantee. If you are not 100% satisfied with any of our products for
                                        any reason, simply send back the unused product to:
                                    </span>
                                </p>
                                <p style={{ marginBottom: "0pt", lineHeight: "normal" }}>
                                    <span>TopShelfNutra Returns</span>
                                    <br />
                                    <span>282 S 671 W</span>
                                </p>
                                <p style={{ marginBottom: "0pt", lineHeight: "normal" }}>
                                    <span>Pleasant Grove, UT 84062</span>
                                </p>
                                <p style={{ marginBottom: "0pt", lineHeight: "normal" }}>
                                    <span>&nbsp;</span>
                                </p>
                                <p style={{ marginBottom: "14pt", lineHeight: "normal" }}>
                                    <span>Be sure to include your name that was on the order, or your order ID number.</span>
                                </p>
                                <h5>For Membership Purchases:</h5>
                                <p style={{ marginBottom: "14pt", lineHeight: "normal" }}>
                                    <span>
                                        TopShelfNutra monthly membership charges can be refunded up to 7 days from the date of the charge if there
                                        were no product purchases used with the membership.
                                    </span>
                                </p>
                                <p style={{ marginBottom: "14pt", lineHeight: "normal" }}>
                                    <span>
                                        TopShelfNutra yearly membership charges will be refunded 100% if requested within 7 days and if there were no
                                        product purchases used with the membership.
                                    </span>
                                </p>
                                <p style={{ marginBottom: "14pt", lineHeight: "normal" }}>
                                    <span>To request a refund of a TopShelfNutra membership, simply send an email to</span>
                                    <span>&nbsp;</span>
                                    <a href="mailto:support@topshelfnutra.com" style={{ textDecoration: "none" }}>
                                        <u>
                                            <span>support@topshelfnutra.com</span>
                                        </u>
                                    </a>
                                    <span>&nbsp;</span>
                                    <span>with the name and/or ID number on the account.</span>
                                </p>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
