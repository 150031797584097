import "./add-card-off-canvas.scss";
import { OffCanvasWrapper } from "../OffCanvasWrapper/OffCanvasWrapper";
import {
    CardElement,
    CardNumberElement,
    Elements,
    ElementsConsumer,
    EmbeddedCheckout,
    EmbeddedCheckoutProvider,
    PaymentElement,
    useCustomCheckout,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { BootstrapButton } from "../BootstrapButton/BootstrapButton";
import { useEffect, useState } from "react";

interface AddCardOffCanvasProps {
    addCardOffCanvasShow: boolean;
    setAddCardOffCanvasShow: React.Dispatch<React.SetStateAction<boolean>>;
    clientSecret: string;
    getPaymentMethods: () => Promise<void>;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

export const AddCardOffCanvas = ({ addCardOffCanvasShow, setAddCardOffCanvasShow, clientSecret, getPaymentMethods }: AddCardOffCanvasProps) => {
    const [showButton, setShowButton] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!addCardOffCanvasShow) {
            setShowButton(false);
            setLoading(false);
        }
    }, [addCardOffCanvasShow]);

    return (
        <OffCanvasWrapper
            offCanvasPlacement="start"
            showOffCanvas={addCardOffCanvasShow}
            setShowOffCanvas={setAddCardOffCanvasShow}
            offCanvasTitle="Add Payment Method"
            offCanvasBody={
                <>
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                        <CheckoutForm
                            setLoading={setLoading}
                            setShowButton={setShowButton}
                            showButton={showButton}
                            setAddCardOffCanvasShow={setAddCardOffCanvasShow}
                            loading={loading}
                            getPaymentMethods={getPaymentMethods}
                        />
                    </Elements>
                </>
            }
            fullWidth
        />
    );
};

interface CheckoutFormProps {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setShowButton: React.Dispatch<React.SetStateAction<boolean>>;
    showButton: boolean;
    setAddCardOffCanvasShow: React.Dispatch<React.SetStateAction<boolean>>;
    loading: boolean;
    getPaymentMethods: () => Promise<void>;
}

const CheckoutForm = ({ setLoading, setShowButton, showButton, setAddCardOffCanvasShow, loading, getPaymentMethods }: CheckoutFormProps) => {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState("");

    const handleAddCardClick = async (e: any) => {
        if (!stripe || !elements) {
            return;
        }
        setLoading(true);

        let { error, setupIntent } = await stripe.confirmSetup({
            elements,
            redirect: "if_required",
        });
        if (error && error.message) {
            return setErrorMessage(error.message);
        }
        if (setupIntent) {
            getPaymentMethods();
            setAddCardOffCanvasShow(false);
        }
    };

    return (
        <form>
            {errorMessage.length > 0 && <div>{errorMessage}</div>}
            <PaymentElement
                onReady={() =>
                    setTimeout(() => {
                        setShowButton(true);
                    }, 100)
                }
            />
            {showButton && (
                <BootstrapButton
                    disabled={loading}
                    className="w-100 mt-4"
                    title="Add Payment Method"
                    variant="primary"
                    onClick={handleAddCardClick}
                />
            )}
        </form>
    );
};
