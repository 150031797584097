import { Col, Container, Row } from "react-bootstrap";
import { ProductDiscountCreditApi, ProductDiscountCreditItem } from "../../../apis/ProductDiscountCreditApi";
import { useEffect, useState } from "react";
import { useAuthenticatedUser } from "../../../context/AuthContext";
import { Member } from "../../../apis/MemberApi";
import { StageTo } from "../AccountPage";

interface LedgerProps {
    pdcBalance: number;
    pdcLedger: ProductDiscountCreditItem[];
}

export const Ledger = ({ pdcBalance, pdcLedger }: LedgerProps) => {
    return (
        <Container fluid className="stage-container">
            <Row>
                <Col className="mt-3 mt-sm-3 mt-md-3 mt-lg-0 mb-5 wrapper-tab wrapper-pdc-ledger d-block" data-navigation="">
                    <div className="fs-4 mb-4 fw-bold">
                        Current Balance <span className="badge text-bg-success pill-green fs-5">{pdcBalance}</span>
                    </div>

                    <div className="fs-5 fw-bold mt-4">Account Ledger</div>
                        {pdcLedger.length > 0 ? (
                            <ul className="list-group list-group-flush mt-2">
                                {pdcLedger.map((ledgerItem: ProductDiscountCreditItem, i: number) => {
                                    return (
                                        <li key={i} className="list-group-item d-flex justify-content-between align-items-center">
                                            <div className="d-flex flex-column">
                                                <span className="fs-6 fw-bold">{ledgerItem.pdc_source}</span>
                                                <span className="fs-6 text-italic">{new Date(ledgerItem.created_at).toLocaleDateString()}</span>
                                            </div>
                                            {ledgerItem.pdc_debit !== null && (
                                                <span className={`badge pill-red rounded-pill fs-5`}>- {ledgerItem.pdc_debit}</span>
                                            )}
                                            {ledgerItem.pdc_credit !== null && (
                                                <span className={`badge pill-green rounded-pill fs-5`}>+ {ledgerItem.pdc_credit}</span>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            <div className="text-center">Your ledger is currently empty.</div>
                        )}
                </Col>
            </Row>
        </Container>
    );
};
