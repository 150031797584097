import react from "react";
import { Button, Form } from "react-bootstrap";
import "./bootstrap-button.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface BootstrapButtonProps {
    title: string;
    className?: string;
    type?: "button";
    onClick?: any;
    variant?: "primary" | "secondary" | "tertiary" | "danger" | "warning" | "outline-danger" | "outline-primary";
    loading?: boolean;
    disabled?: boolean;
    showSuccess?: { message: string; show: boolean };
}

export const BootstrapButton = ({
    title,
    className,
    type = "button",
    onClick,
    variant = "primary",
    loading = false,
    disabled = false,
    showSuccess = { message: "", show: false },
}: BootstrapButtonProps) => {
    return (
        <>
            <Button
                variant={variant}
                onClick={onClick}
                type={type}
                className={`px-3 bootstrap-button shadow-none ${className ? className : ""} ${variant === "secondary" ? "tsn-white" : ""} ${
                    showSuccess.show ? "bootstrap-button__green" : ""
                }`}
                disabled={disabled || loading || showSuccess.show}
            >
                {showSuccess.show ? showSuccess.message : loading ? <FontAwesomeIcon className="fs-4" icon={faSpinner} spin /> : title}
            </Button>
        </>
    );
};
