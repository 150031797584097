import { UUID } from "crypto";
import { ApiCaller } from "./ApiCaller";

export type ProductDiscountCreditItem = {
    member_id: UUID;
    pdc_credit: number;
    pdc_debit: number;
    created_at: Date;
    pdc_source: string;
    comments: string;
};


export class ProductDiscountCreditApi {
    private static apiCaller = new ApiCaller("productdiscountcredit");

    public static GetMemberLedger(member_id: UUID) {
        return this.apiCaller.GET(`/ledger/${member_id}`);
    }

    public static GetMemberBalance(member_id: UUID) {
        return this.apiCaller.GET<number>(`/balance/${member_id}`);
    }
}
