import "./subscription-off-canvas.scss";
import { OffCanvasWrapper } from "../OffCanvasWrapper/OffCanvasWrapper";
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect } from "react";

interface SubscriptionOffCanvasProps {
    subscriptionOffCanvasShow: boolean;
    setSubscriptionOffCanvasShow: React.Dispatch<React.SetStateAction<boolean>>;
    clientSecret: string;
    handleIsMemberActive: () => Promise<void>;
    getMembershipDetails: () => Promise<void>;
    setLoadingAccountPage: React.Dispatch<React.SetStateAction<boolean>>;
    subscriptionName: string;
    upgradeMonthlySubscription: {
        upgrade: boolean;
        cancelMembership: (membership: any) => Promise<void>;
        membershipToCancel: any;
    };
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

export const SubscriptionOffCanvas = ({
    subscriptionOffCanvasShow,
    setSubscriptionOffCanvasShow,
    clientSecret,
    handleIsMemberActive,
    getMembershipDetails,
    setLoadingAccountPage,
    subscriptionName,
    upgradeMonthlySubscription,
}: SubscriptionOffCanvasProps) => {
    return (
        <OffCanvasWrapper
            offCanvasPlacement="end"
            showOffCanvas={subscriptionOffCanvasShow}
            setShowOffCanvas={setSubscriptionOffCanvasShow}
            offCanvasTitle={`${subscriptionName} Purchase`}
            offCanvasBody={
                <>
                    <EmbeddedCheckoutProvider
                        stripe={stripePromise}
                        options={{
                            clientSecret,
                            onComplete: async () => {
                                if (upgradeMonthlySubscription.upgrade) {
                                    upgradeMonthlySubscription.cancelMembership(upgradeMonthlySubscription.membershipToCancel);
                                } else {
                                    setLoadingAccountPage(true);
                                    getMembershipDetails();
                                }
                                setSubscriptionOffCanvasShow(false);
                            },
                        }}
                    >
                        <EmbeddedCheckout />
                    </EmbeddedCheckoutProvider>
                </>
            }
            fullWidth
        />
    );
};
