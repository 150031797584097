import { Container } from "react-bootstrap";
import "./loader.scss";

interface LoaderProps {}

export const Loader = ({}: LoaderProps) => {
    return (
        <Container fluid className="loader-container">
            <div className="p-2 loader">
                <div className="square"></div>
                <div className="square"></div>
                <div className="square last"></div>
                <div className="square clear"></div>
                <div className="square"></div>
                <div className="square last"></div>
                <div className="square clear"></div>
                <div className="square "></div>
                <div className="square last"></div>
            </div>
        </Container>
    );
};
