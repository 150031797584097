import { Col, Container, Row } from "react-bootstrap";
import { BootstrapInput } from "../../../components/BootstrapInput/BootstrapInput";
import { Member, MemberApi } from "../../../apis/MemberApi";
import { useAuthenticatedUser } from "../../../context/AuthContext";
import { BootstrapButton } from "../../../components/BootstrapButton/BootstrapButton";
import { toast } from "react-toastify";
import { useState } from "react";
import { AuthApi } from "../../../apis/AuthApi";

interface DetailsProps {
    accountDetailsForm: Member;
    setAccountDetailsForm: React.Dispatch<React.SetStateAction<Member | undefined>>;
    updatePatchingProps: (property: string, value: any, remove?: boolean) => void;
    setPatchingProps: React.Dispatch<
        React.SetStateAction<
            {
                prop: string;
                value: string;
            }[]
        >
    >;
    patchingProps: {
        prop: string;
        value: string;
    }[];
}

export const Details = ({ accountDetailsForm, setAccountDetailsForm, updatePatchingProps, setPatchingProps, patchingProps }: DetailsProps) => {
    const { authMember, updateAuthMember, logout } = useAuthenticatedUser();
    const [newEmail, setNewEmail] = useState("");
    const [emailIsValid, setEmailIsValid] = useState(true);
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");

    const patchMemberDetailsClick = async () => {
        if (accountDetailsForm) {
            let obj: any = {};
            for (let i = 0; i < patchingProps.length; i++) {
                let patchedProp = patchingProps[i];
                obj[patchedProp.prop] = patchedProp.value;
            }
            let response = await MemberApi.PatchOneMember(accountDetailsForm.member_id, obj);
            if (response.success && authMember?.active && authMember.default_payment_method) {
                updateAuthMember({ ...accountDetailsForm, active: authMember.active, default_payment_method: authMember.default_payment_method });
                setPatchingProps([]);
                toast("Successfully Updated your account.");
            } else {
                toast(response.message, { type: "error" });
            }
        }
    };

    const updateEmailClick = async () => {
        setEmailIsValid(true);
        setEmailErrorMessage("");
        if (accountDetailsForm) {
            let memberExistResponse = (await MemberApi.MemberAlreadyExists(newEmail.toLowerCase())) as any;
            if (memberExistResponse.success) {
                if (!memberExistResponse.emailExists) {
                    let response = await MemberApi.PatchOneMember(accountDetailsForm.member_id, { email: newEmail });
                    if (response.success) {
                        logout();
                    } else {
                        toast(response.message, { type: "error" });
                    }
                } else {
                    setEmailIsValid(false);
                    setEmailErrorMessage("Sorry, the email you've inputed is already in use.");
                }
            }
        }
    };

    const updatePasswordClick = async () => {
        if (currentPassword.length > 0 && newPassword.length > 0 && newPassword === confirmNewPassword) {
            let response = await AuthApi.ResetPasswordAuthenticated(currentPassword, newPassword).catch(console.error);
            if (response.success) {
                logout();
            } else {
                toast(response.message, { type: "error" });
            }
        }
    };

    return (
        <Container fluid className="mb-5 wrapper-tab d-block stage-container">
            <Row className="mt-3 mt-sm-3 mt-md-3 mt-lg-0 mb-5 py-6 wrapper-details">
                <span className="fs-4 mb-4 fw-bold">Details</span>
                <Row>
                    <Col className="col-12" sm={12} md={5} lg={5}>
                        <BootstrapInput
                            label="Let us know who referred you"
                            value={accountDetailsForm.how_heard}
                            onChange={(e) => {
                                setAccountDetailsForm({ ...accountDetailsForm, how_heard: e.target.value });
                                if (authMember) {
                                    updatePatchingProps("how_heard", e.target.value, e.target.value === authMember.how_heard);
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="col-12" sm={12} md={5}>
                        <BootstrapInput
                            label="First name"
                            value={accountDetailsForm.first_name}
                            onChange={(e) => {
                                setAccountDetailsForm({ ...accountDetailsForm, first_name: e.target.value });
                                if (authMember) {
                                    updatePatchingProps("first_name", e.target.value, e.target.value === authMember.first_name);
                                }
                            }}
                        />
                    </Col>
                    <Col className="col-12" sm={12} md={5}>
                        <BootstrapInput
                            label="Last name"
                            value={accountDetailsForm.last_name}
                            onChange={(e) => {
                                setAccountDetailsForm({ ...accountDetailsForm, last_name: e.target.value });
                                if (authMember) {
                                    updatePatchingProps("last_name", e.target.value, e.target.value === authMember.last_name);
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col sm={12} md={5}>
                        <BootstrapInput
                            label="Phone number"
                            value={accountDetailsForm.phone_number ?? ""}
                            type="phone"
                            onChange={(e) => {
                                setAccountDetailsForm({
                                    ...accountDetailsForm,
                                    phone_number: e.target.value,
                                });
                                if (authMember) {
                                    updatePatchingProps(
                                        "phone_number",
                                        e.target.value === "" ? null : e.target.value,
                                        (e.target.value === "" ? null : e.target.value) === authMember.phone_number
                                    );
                                }
                            }}
                        />
                    </Col>
                    <Col className="mt-sm-3 mt-md-0 mt-3" sm={12} md={5}>
                        <BootstrapInput
                            label="Text Messages"
                            value={accountDetailsForm.allow_text_messages}
                            type="checkbox"
                            onChange={(e) => {
                                setAccountDetailsForm({
                                    ...accountDetailsForm,
                                    allow_text_messages: !accountDetailsForm.allow_text_messages,
                                });
                                if (authMember) {
                                    updatePatchingProps(
                                        "allow_text_messages",
                                        !accountDetailsForm.allow_text_messages,
                                        !accountDetailsForm.allow_text_messages === authMember.allow_text_messages
                                    );
                                }
                            }}
                        />
                        <label className="form-check-label  mt-2">I agree to receive text messages from TopShelfNutra</label>
                        <div className="fs-6 mt-2">
                            Note: We will send you a text message with instructions on how to verify your phone number if you choose to receive text
                            messages.
                        </div>
                    </Col>
                </Row>

                <div className="d-flex py-4 w-50">
                    <div className="mb-3">
                        <BootstrapButton
                            disabled={patchingProps.length === 0}
                            onClick={patchMemberDetailsClick}
                            variant="secondary"
                            title="Update Details"
                        />
                    </div>
                </div>
            </Row>

            <div className="wrapper-email border-top py-4">
                <h5 className="fs-4 mb-4 fw-bold">Email</h5>
                <div className="fs-6 fw-bold text-danger">
                    Note: Changing your email will log you out and require you to go to your email and follow instructions. Until then your account
                    will be disabled.
                </div>
                <Row className="mt-3">
                    {!emailIsValid && (
                        <Col className="mb-2 tsn-red" sm={12} md={12}>
                            <div>{emailErrorMessage}</div>
                        </Col>
                    )}
                    <Col sm={12} md={5}>
                        <BootstrapInput
                            label="Email"
                            value={newEmail}
                            placeholder={accountDetailsForm.email}
                            valid={emailIsValid}
                            onChange={(e) => {
                                setNewEmail(e.target.value);
                            }}
                        />
                    </Col>
                </Row>

                <div className="mt-3">
                    <BootstrapButton
                        disabled={
                            newEmail.length === 0 || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(newEmail) || newEmail === accountDetailsForm.email
                        }
                        title="Update Email"
                        variant="secondary"
                        onClick={updateEmailClick}
                    />
                </div>
            </div>
            <div className="wrapper-password border-top py-4">
                <h5 className="fs-4 mb-4 fw-bold">Password</h5>

                <div className="fs-6 fw-bold text-danger mt-4 mb-3">
                    Note: Changing your password will log you out and require you to sign back in.
                </div>

                <Row className="mt-3">
                    <Col sm={12} md={5}>
                        <BootstrapInput
                            label="Current Password"
                            value={currentPassword}
                            type="password"
                            onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col sm={12} md={5}>
                        <BootstrapInput label="New Password" value={newPassword} type="password" onChange={(e) => setNewPassword(e.target.value)} />
                    </Col>

                    <Col sm={12} md={5}>
                        <BootstrapInput
                            label="Confirm New Password"
                            value={confirmNewPassword}
                            type="password"
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                        />
                    </Col>
                </Row>

                <div className="mt-4 mb-3">
                    <BootstrapButton
                        disabled={
                            currentPassword.length === 0 ||
                            newPassword.length === 0 ||
                            confirmNewPassword.length === 0 ||
                            newPassword !== confirmNewPassword
                        }
                        onClick={updatePasswordClick}
                        variant="secondary"
                        title="Update Password"
                    />
                </div>
            </div>

            {/* <div className="wrapper-delete border-top py-4">
                                                        <h5 className="fs-4 mb-4 fw-bold">Delete Account</h5>
                                                        <p className="mb-2">Would you like to delete your account?</p>
                                                        <p className="mb-5 wrapper-delete-pdc-notic">
                                                            This will remove you from the TopSheflNutra family, while you can return, your spot in the
                                                            family tree will be based on your next account creation date. Any product discount credits
                                                            associated with this account will also be deleted.
                                                        </p>

                                                        <a href="#" className="btn btn-outline-danger btn-delete">
                                                            I want to delete my account
                                                        </a>
                                                    </div>
                                                    <div className="d-flex mt-2 d-block d-md-none my-10"></div> */}
        </Container>
    );
};
