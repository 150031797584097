import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { AuthApi } from "../../apis/AuthApi";
import { useAuthenticatedUser } from "../../context/AuthContext";
import { BootstrapButton } from "../BootstrapButton/BootstrapButton";
import { BootstrapInput } from "../BootstrapInput/BootstrapInput";
import { OffCanvasWrapper } from "../OffCanvasWrapper/OffCanvasWrapper";
import "./signin-off-canvas.scss";
import { recalculateCartItems, useShoppingCart } from "../../context/ShoppingCartContext";
import { CustomerApi } from "../../apis/CustomerApi";

interface HeaderProps {
    signInOffCanvasShow: boolean;
    setSignInOffCanvasShow: React.Dispatch<React.SetStateAction<boolean>>;
    setPasswordResetOffCanvasShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SignInOffCanvas = ({ signInOffCanvasShow, setSignInOffCanvasShow, setPasswordResetOffCanvasShow }: HeaderProps) => {
    const [loadingLogin, setLoadingLogin] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [authCode, setAuthCode] = useState("");
    const [showAuthCodeLayout, setShowAuthCodeLayout] = useState(false);
    const [emailValid, setEmailValid] = useState(true);
    const [passwordValid, setPasswordValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const { setAuthMember, setIsLoggedIn } = useAuthenticatedUser();
    const [toggleResendAuthCodeEmail, setToggleResendAuthCodeEmail] = useState(false);
    const { cartItems, setCartItems, allProducts } = useShoppingCart();
    const navigate = useNavigate();

    useEffect(() => {
        if (window.location.search.includes("updateCC=true")) {
            setErrorMessage("After you login, please go and update your Credit Card information!");
        }
    }, []);

    useEffect(() => {
        if (toggleResendAuthCodeEmail) {
            setTimeout(() => {
                setToggleResendAuthCodeEmail(false);
            }, 5000);
        }
    }, [toggleResendAuthCodeEmail]);

    const loginOnClick = async () => {
        if (email.length === 0 || password.length === 0) {
            setErrorMessage("Please fill out the fields below.");
            return;
        }
        let loginResponse = await AuthApi.Login(email.toLowerCase(), password, authCode.length > 0 ? authCode : undefined).catch(console.error);

        setErrorMessage("");
        setEmailValid(true);
        setPasswordValid(true);
        if (loginResponse.success && !loginResponse.auth_code_required) {
            let memberActive: any = await CustomerApi.CheckActiveMembership(loginResponse.customer_id);
            if (cartItems.length > 0) {
                recalculateCartItems(true, cartItems, setCartItems, allProducts);
            }
            localStorage.setItem("token", loginResponse.token);
            delete loginResponse.token;
            localStorage.setItem("member", JSON.stringify({ ...loginResponse, active: memberActive.valid_member }));
            localStorage.setItem("member-set", "true");
            setAuthMember({ ...loginResponse, active: memberActive.valid_member });
            setIsLoggedIn(true);
            setSignInOffCanvasShow(false);
            clearState();
            if (window.location.search.includes("updateCC=true") && window.location.search.includes("subscription=true")) {
                navigate("/account/?updateCC=true&subscription=true");
            } else if (window.location.search.includes("updateCC=true")) {
                navigate("/account/?updateCC=true");
            } else if (window.location.pathname.includes("/account/verify") || window.location.pathname === "/") {
                navigate("/account/");
            }
        } else if (!loginResponse.success && loginResponse.auth_code_required) {
            setShowAuthCodeLayout(true);
            if (loginResponse.auth_code_again) {
                setErrorMessage(loginResponse.message);
            }
        } else {
            if (loginResponse.message.toLowerCase().includes("email")) {
                setEmailValid(false);
            }
            if (loginResponse.message.toLowerCase().includes("password")) {
                setPasswordValid(false);
            }
            setErrorMessage(loginResponse.message);
        }
        setLoadingLogin(false);
    };

    const clearState = () => {
        setEmail("");
        setPassword("");
        setAuthCode("");
        setShowAuthCodeLayout(false);
    };

    const navigateToSignUp = () => {
        setSignInOffCanvasShow(false);
    };

    const handleResendAuthenticationCodeEmail = async () => {
        let response = await AuthApi.ResendAuthenticationCodeEmail(email).catch(console.error);
        if (response.success) {
            setToggleResendAuthCodeEmail(true);
        } else {
            setErrorMessage("An error has occured trying to resend authorization code. Please contact support at support@gmetrix.com.");
        }
    };

    const handleKeyDownSignIn = (e: any) => {
        if (e.key === "Enter") {
            e.preventDefault();
            setLoadingLogin(true);
            loginOnClick();
        }
    };

    return (
        <OffCanvasWrapper
            offCanvasPlacement="end"
            showOffCanvas={signInOffCanvasShow}
            setShowOffCanvas={setSignInOffCanvasShow}
            offCanvasTitle="Member Sign In"
            fullWidth
            offCanvasBody={
                <Col className="col-12 col-md-12">
                    <div className="mt-3 mb-lg-9 mb-2">
                        <p>Welcome back to TopShelfNutra! Enter your email and password to get started.</p>
                        <div className={`text-center ${errorMessage.length > 0 ? "tsn-red" : "tsn-white"}`}>
                            {errorMessage.length > 0 ? errorMessage : "|"}
                        </div>
                    </div>

                    {!showAuthCodeLayout ? (
                        <Row className="g-3">
                            <div className="container-pre-auth-code d-block">
                                <Col className="col-12">
                                    <BootstrapInput
                                        value={email}
                                        onKeyDown={handleKeyDownSignIn}
                                        onChange={(e) => setEmail(e.target.value)}
                                        label="Email"
                                        type="email"
                                        valid={emailValid}
                                        autoFocus
                                    />
                                </Col>
                                <Col className="col-12 mt-2">
                                    <div className="password-field position-relative">
                                        <BootstrapInput
                                            value={password}
                                            onKeyDown={handleKeyDownSignIn}
                                            onChange={(e) => setPassword(e.target.value)}
                                            label="Account password"
                                            type="password"
                                            valid={passwordValid}
                                        />
                                    </div>
                                </Col>
                                <div className="d-flex mt-2">
                                    <div className="ms-auto">
                                        Forgot password?{" "}
                                        <a
                                            onClick={() => {
                                                setPasswordResetOffCanvasShow(true);
                                                setSignInOffCanvasShow(false);
                                            }}
                                            role="button"
                                            className="text-reset"
                                        >
                                            Reset It
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <Col className="col-12 d-grid">
                                <BootstrapButton
                                    title="Sign In"
                                    loading={loadingLogin}
                                    onClick={() => {
                                        setLoadingLogin(true);
                                        loginOnClick();
                                    }}
                                    variant="secondary"
                                />
                            </Col>
                            <div className="container-member-signup">
                                Not a member?{" "}
                                <Link to="account/signup" onClick={navigateToSignUp} className="ml-1 text-reset" role="button">
                                    Sign Up
                                </Link>
                            </div>
                        </Row>
                    ) : (
                        <div className="container-authcode-entry col-12 d-grid">
                            <label className="form-label">
                                <b>Please enter the Authorization Code from your email:</b>
                            </label>
                            <BootstrapInput
                                value={authCode}
                                onChange={(e) => setAuthCode(e.target.value)}
                                label="Authorization code"
                                valid={passwordValid}
                            />
                            <BootstrapButton className="mt-4" variant="secondary" title="Verify" onClick={loginOnClick} />
                            <div className="mt-4 text-center">
                                Didn't get your Authorization Code? Click here:{" "}
                                <span
                                    onClick={handleResendAuthenticationCodeEmail}
                                    className={`mt-2 text-center ${toggleResendAuthCodeEmail ? "sent-auth-text" : "resend-auth-text"}`}
                                >
                                    {toggleResendAuthCodeEmail ? `Authorization Code sent to ${email}.` : "Resend Authorization Code Email."}
                                </span>
                            </div>
                        </div>
                    )}
                </Col>
            }
        />
    );
};
